<template>
  <div class="table">
    <header class="table-hd">
      <div class="table-hd-info">
        <slot name="table-hd"></slot>
      </div>
      <div v-if="total" class="table-hd-right">共{{ total }}个</div>
    </header>
    <div class="table-grid">
      <div v-if="datas.length === 0" class="empty">
        <el-empty description="抱歉，暂无数据" />
      </div>
      <el-row class="table-grid-row">
        <div v-for="(item, idx) in datas" :key="idx" class="table-grid-col">
          <GoodsCard :item="item" :price="price" />
        </div>
      </el-row>
    </div>
    <div class="table-ft pt-3">
      <el-pagination
        class="mb-5"
        background
        layout="prev, pager, next,jumper"
        :total="total"
        hide-on-single-page
        :page-size="20"
        v-model="currentPage"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { get } from 'http'
import { ref, defineProps, computed, defineEmits } from 'vue'
import GoodsCard from './goodsCard.vue'
const props = defineProps({
  datas: {
    type: [Array],
    default: () => {
      return []
    }
  },
  sourceData: {
    type: [Object],
    default: () => {
      return {}
    }
  },
  price: {
    type: [Boolean],
    default: false
  }
})
const total = computed(() => props?.sourceData?.totalCount || 0)
const totalPage = computed(() => props?.sourceData?.totalPage || 0)
const currentPage = computed({
  get() {
    props?.sourceData?.page || 1
  },
  set(val) {
    return val
  }
})
const emit = defineEmits(['handleCurrentChange'])
const handleCurrentChange = (e) => {
  emit('handleCurrentChange', e)
}
</script>

<style lang="scss" scoped>
.table {
  &-hd {
    background: #f5f7f6;
    border: 2px solid rgba(194, 194, 194, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    &-right {
      font-size: 14px;
      color: #828080;
    }
  }
  &-grid {
    &-row {
      margin-left: -3px;
      margin-right: -3px;
    }
    &-col {
      width: 25%;
      padding: 0 3px 10px;
    }
  }
  &-ft {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
