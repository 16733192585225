<template>
  <div class="filter mb-2">
    <div
      v-if="!brand_title && brandDatas && brandDatas.length"
      class="filter-tr"
    >
      <div class="filter-label">
        <strong>品牌：</strong>
      </div>
      <div class="filter-content">
        <div class="filter-inner">
          <div class="brand">
            <div
              class="brand-content"
              v-if="show"
              :class="{ 'brand-content-active': current == 1 }"
            >
              <div class="brand-inner">
                <Brand
                  v-for="(item, idx) in brandDatas"
                  :key="idx"
                  :item="item"
                  @click="onItem(item)"
                  :class="
                    filterData &&
                    filterData.length &&
                    filterData.find((el) => el.alias == item.alias)
                      ? 'brand-item-active'
                      : ''
                  "
                />
              </div>
            </div>
          </div>
          <div class="filter-inner-ft">
            <div class="multiple-list">
              <a
                v-for="(item, idx) in filterData"
                :key="idx"
                href="javascript:;"
                class="multiple-item"
                @click="onItem(item)"
              >
                <el-icon>
                  <Select />
                </el-icon>
                {{ item.title }}
              </a>
            </div>
            <el-button
              v-if="isMultiple"
              type="info"
              plain
              @click="onMultipleCancel"
              >取消</el-button
            >
          </div>
        </div>
        <div class="filter-after" v-if="!isMultiple">
          <el-button
            v-if="brandDatas && brandDatas.length > 5"
            @click="onElse(1)"
            >{{ current == 1 ? '收起' : '更多' }}
            <el-icon>
              <ArrowUp v-if="current == 1" />
              <ArrowDown v-else />
            </el-icon>
          </el-button>
          <el-button @click="onMultiple"
            >多选
            <el-icon>
              <Plus />
            </el-icon>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, defineEmits, defineProps, watch } from 'vue'
import { ArrowDown, Plus, ArrowUp, Select } from '@element-plus/icons-vue'
import { partnerList, brandList } from '@/api/app'
import Brand from './Brand.vue'
import _ from 'lodash'
import { useRoute, useRouter } from 'vue-router'
const emit = defineEmits(['filterBrand'])
const brandDatas = ref([])
const props = defineProps({
  goods_sort_alias: {
    type: [String],
    default: ''
  }
})
console.log(props.goods_sort_alias)
const init = () => {
  brandList({ goods_sort_alias: props.goods_sort_alias }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      const { items } = data
      brandDatas.value = items
    }
  })
}
init()
const current = ref('')
const show = ref(true)
const onElse = (idx) => {
  show.value = false
  if (current.value && current.value == idx) {
    current.value = ''
  } else {
    current.value = idx
  }
  nextTick(() => {
    show.value = true
  })
}
const isMultiple = ref(false)
const filterData = ref([])
const onMultiple = () => {
  if (!current.value) {
    onElse(1)
  }
  isMultiple.value = true
  filterData.value = []
}
const onMultipleCancel = () => {
  onElse(1)
  isMultiple.value = false
  filterData.value = []
}
const router = useRouter()
const route = useRoute()
const onItem = (item) => {
  if (isMultiple.value) {
    const index = filterData.value.findIndex((el) => el.alias == item.alias)
    if (index >= 0) {
      _.pullAt(filterData.value, index)
    } else {
      filterData.value.push(item)
    }
    console.log(
      (filterData.value && filterData.value.map((n) => n.id)).join(',')
    )
    emit('filterBrand', {
      id: (filterData.value && filterData.value.map((n) => n.id)).join(',')
    })
  } else {
    emit('filterBrand', item)
    filterData.value = []
  }
}
const brand_title = ref('')
watch(
  route,
  (e) => {
    const { query } = e || {}
    const { brand_title: brand_title_str } = query
    brand_title.value = brand_title_str
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.filter {
  border-top: 1px solid #ddd;
  &-tr {
    display: flex;
    border-bottom: 1px solid #ddd;
    background: #f3f3f3;
    line-height: 34px;
  }
  &-label {
    padding-left: 20px;
    padding-top: 10px;
    width: 130px;
  }
  &-content {
    background-color: #fff;
    flex: 1;
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  &-inner {
    flex: 1;
    width: 70%;
    padding-left: 10px;
    &-ft {
      text-align: center;
    }
  }

  &-after {
    width: 160px;
    text-align: right;
  }
}
.brand {
  &-content {
    height: 122px;
    overflow: hidden;
    padding-top: 1px;
    &-active {
      height: 242px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  &-inner {
    display: flex;
    flex-wrap: wrap;
  }
}
.multiple {
  &-list {
    text-align: left;
  }
  &-item {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    .el-icon {
      border: 1px solid;
    }
  }
}
</style>
