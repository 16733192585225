<template>
  <div class="sort pt-4">
    <el-container class="container">
      <div class="sort-hd">
        <div class="sort-hd__hd">
          <router-link to="/">首页</router-link>
          <div v-if="brandTitle" class="brand-tag">
            <el-icon>
              <ArrowRight />
            </el-icon>
            <el-tag closable :disable-transitions="false" @close="handleClose" size="large">
              {{ brandTitle }}
            </el-tag>
          </div>
          <div class="brand-tag" @click="onCheck(current_parent_item)">
            <el-icon>
              <ArrowRight />
            </el-icon>
            {{ current_parent_item.title || '' }}
          </div>
          <div v-if="cellObj && cellObj['title']" class="brand-tag" @click.stop="onItemCheck(cellObj)">
            <el-icon>
              <ArrowRight />
            </el-icon>
            {{ (cellObj && cellObj['title']) || '' }}
          </div>
          <div v-if="specObj && specObj['title']" class="brand-tag" @click.stop="onItemCheck(cellObj, specObj)">
            <el-icon>
              <ArrowRight />
            </el-icon>
            {{ (specObj && specObj['title']) || '' }}
          </div>
        </div>
        <div class="sort-hd__bd">
          {{ current_parent_item.title || '' }}
        </div>
        <div class="sort-hd__ft">
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item, idx) in sortDatas" :title="item.title" :name="item.id" :key="idx"
              :disabled="!item.items || item.items.length == 0">
              <template #title>
                <a class="sort-hd__title" href="javascript:;" @click.stop="onItemCheck(item)" :class="{
                  'sort-hd__title-active':
                    cellObj && cellObj['id'] && cellObj['id'] == item['id']
                }">{{ item.title }}</a>
                <!-- @click.stop="onCheck(item)" -->
              </template>
              <a href="javascript:;" class="sort-cell__title" :class="{
                'sort-cell__title-active':
                  specObj && specObj['id'] && specObj['id'] == cell['id']
              }" v-for="(cell, index) in item.items" :key="index" @click.stop="onItemCheck(item, cell, index)">
                {{ cell.title }}
              </a>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="sort-bd">
        <div class="sort-bd-control">
          <Filter :goods_sort_alias="route.query.alias" @filterBrand="filterBrand" />
        </div>
        <div class="sort-bd-table">
          <Goods :datas="goodsDatas" :source-data="goodsSourceData" @handleCurrentChange="handleCurrentChange"
            :price="price">
            <template #table-hd>
              <el-radio-group class="sort-bd-radio" v-model="radio" size="large" @change="onChange">
                <el-radio-button label="0">默认</el-radio-button>
                <el-radio-button label="1">销量</el-radio-button>
                <el-radio-button label="2">最新</el-radio-button>
              </el-radio-group>
            </template>
          </Goods>
          <!-- <Goods v-if="rankingDatas && rankingDatas.length" :datas="rankingDatas" :price="price">
            <template #table-hd>
              <h3 class="sort-bd-table-title">销售排行榜</h3>
            </template>
          </Goods> -->
          <!-- <Goods v-if="hisDatas && hisDatas.length" :datas="hisDatas">
            <template #table-hd>
              <h3 class="sort-bd-table-title">您的浏览记录</h3>
            </template>
          </Goods> -->
        </div>
      </div>
    </el-container>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
import { goodsSortListAll, goodsList, goodsHistoryList } from '@/api/goods'
import { useRoute, useRouter } from 'vue-router'
import { ArrowRight } from '@element-plus/icons-vue'
import Goods from './components/goods.vue'
import Filter from './components/Filter.vue'
import _ from 'lodash'
const props = defineProps({
  price: {
    type: [Boolean],
    default: false
  }
})
const route = useRoute()
const router = useRouter()
const sortDatas = ref([])
const brandTitle = ref('')
const current_parent_item = ref({})
const cellObj = ref({})
const specObj = ref({})
const currentAlias = ref('')
const init = (alias) => {
  goodsSortListAll({ parent_id: alias }).then((res) => {
    const { error, data } = res
    if (error == 0) {
      const { items, parent_item } = data
      sortDatas.value = items
      if (parent_item) {
        current_parent_item.value = parent_item
      }

      //跳转过来分类选中菜单栏
      if (currentAlias) {
        sortDatas.value.forEach(element => {
          if (element.alias == currentAlias.value) {
            activeNames.value = element.id
            cellObj.value = element
            return;
          }
          if (element.items) {
            element.items.forEach(item => {
              if (item.alias == currentAlias.value) {
                activeNames.value = element.id
                cellObj.value = element
                specObj.value = item
                return;
              }
            })
          }
        });
      }
    }
  })
}
const goodsDatas = ref([])
const goodsSourceData = ref([])
const parmas = ref({})

const goodsInit = (params, page = 1, pageSize = 20) => {
  const { alias, brand_ids, fn, sales, is_new } = params
  params.value = {
    alias,
    brand_ids,
    sales,
    is_new
  }
  const tempData = {
    page,
    pageSize,
    goods_sort_alias: alias,
    brand_ids,
    sales,
    is_new
  }
  goodsList(tempData).then((res) => {
    const { error, data } = res
    if (error === 0) {
      if (fn) {
        fn(data)
        return
      }
      goodsSourceData.value = data
      const { items } = data
      goodsDatas.value = items
    }
  })
}
const activeNames = ref([])

const radio = ref('0')
watch(radio, (e) => {
  console.log(e)
})
const title = ref('')
// const rankingDatas = ref([])
const hisDatas = ref([])
const brand_ids = ref('')
watch(
  route,
  (e) => {
    const {
      alias,
      title: queryTitle,
      brand_title,
      brand_id,
      id
    } = e?.query || {}
    title.value = decodeURIComponent(queryTitle)
    brand_ids.value = brand_id
    if (alias) {
      currentAlias.value = alias
      brandTitle.value = brand_title
      init(id)
      const tempData = _.cloneDeep(parmas.value)
      tempData['alias'] = alias
      tempData['brand_ids'] = brand_id
      goodsInit(tempData)
      // goodsInit(
      //   {
      //     alias,
      //     fn: (e) => {
      //       rankingDatas.value = e?.items
      //     },
      //     sales: 1,
      //   },
      //   1,
      //   4
      // )
      // goodsHistoryList({ page: 1, pageSize: 4 }).then((res) => {
      //   const { error, data } = res
      //   if (error == 0) {
      //     const { items } = data
      //     hisDatas.value = items
      //   }
      // })
    }
  },
  { immediate: true }
)
const handleCurrentChange = (e) => {
  const tempData = _.cloneDeep(parmas.value)
  tempData['alias'] = currentAlias.value
  goodsInit(tempData, e)
}
const onCheck = (item) => {
  cellObj.value = {}
  specObj.value = {}
  const { alias, id } = item || {}
  const tempData = _.cloneDeep(parmas.value)
  tempData['alias'] = alias
  goodsInit(tempData)
}
const onItemCheck = (list, item) => {
  cellObj.value = list || {}
  specObj.value = item || {}
  const tempData = _.cloneDeep(parmas.value)
  if (item) {
    const { alias } = item
    tempData['alias'] = alias
    goodsInit(tempData)
  } else {
    const { alias } = list
    tempData['alias'] = alias
    goodsInit(tempData)
  }
  console.log('cellObj',cellObj,specObj)
}
const onChange = (e) => {
  const { alias } = route.query || {}
  const tempData = _.cloneDeep(parmas.value)
  tempData['alias'] = alias
  if (e == 1) {
    tempData['sales'] = 1
  } else if (e == 2) {
    tempData['is_new'] = 1
  }
  goodsInit(tempData)
}
const filterBrand = (item) => {
  const { title: logoTitle, id: brand_id } = item || {}
  const { id } = route.query || {}
  const query = {
    alias: currentAlias.value,
    brand_id,
    id,
    brand_title: logoTitle || ''
  }
  if (!logoTitle) {
    delete query.brand_title
  }
  const { name } = route || {}
  router.push({
    name,
    query
  })
}
const handleClose = () => {
  filterBrand({})
}
</script>

<style lang="scss" scoped>
.sort {
  &-hd {
    width: 200px;

    &__hd {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 16px;
      font-weight: 300;
      color: #191818;
      padding-bottom: 35px;
      margin-right: -40px;

      a {
        color: #191818;
      }
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #191818;

      &:hover {
        text-decoration: underline;
      }

      &-active {
        color: var(--el-color-primary);
      }
    }

    &__bd {
      font-size: 24px;
      font-weight: bold;
      color: #191818;
      padding-bottom: 10px;
      border-bottom: 2px solid #5e5e5e;
    }
  }

  &-bd {
    flex: 1;
    width: 75%;
    padding-left: 46px;

    &-radio {
      :deep(.el-radio-button) {
        --el-radio-button-checked-bg-color: transparent;
        --el-radio-button-checked-text-color: var(--el-color-primary);

        .el-radio-button__inner {
          background-color: transparent;
          border-radius: 0;
        }
      }
    }

    &-table {
      &-title {
        font-size: 16px;
        line-height: 43px;
        font-weight: 500;
        color: #3d3e3e;
        margin: 0;
        padding-left: 20px;
      }
    }
  }

  :deep(.el-collapse-item__content) {
    padding: 0;
  }

  :deep(.el-collapse) {
    margin-left: -25px;

    .el-collapse {
      &-item {
        position: relative;

        &__header {
          padding-left: 25px;
          position: relative;
        }

        &__arrow {
          position: absolute;
          left: 0;
          top: 19.5px;
          background-color: #333;
          display: block;
          font-size: 8px;

          // margin-top: -5px;
          &::after {
            content: '';
            position: absolute;
            display: block;
            border: 0.5em solid #fff;
            border-bottom: 1em solid transparent;
            border-top: 0 solid transparent;
            top: 0;
            left: 0;
            right: 0;
          }

          &.is-active {
            transform-origin: center;
            transform: rotate(180deg);
          }
        }

        &.is-disabled {
          .el-collapse-item {
            &__arrow {
              display: none;
            }

            &__header {
              cursor: pointer;
            }
          }

          .sort-hd__title {
            display: block;
            width: 100%;
            height: 100%;

            &-active {
              color: var(--el-color-primary);
            }
          }
        }
      }
    }
  }

  &-cell {
    &__title {
      font-size: 15px;
      font-weight: 300;
      color: #191818;
      padding-left: 25px;
      display: block;

      &:hover {
        text-decoration: underline;
      }

      &-active {
        color: var(--el-color-primary);
      }
    }
  }
}

.brand {
  &-tag {
    display: flex;
    align-items: center;
    cursor: pointer;

    :deep(.el-tag--large) {
      font-size: 18px;
    }
  }
}

:deep(.el-collapse-item__header) {
  line-height: 20px;
  padding-top: 10px
}
</style>
